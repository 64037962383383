import { useQuery, UseQueryOptions } from "react-query";
import { ApiReport } from "../../models";
import { useRecoilValue } from "recoil";
import { licenseAtom } from "../../state";
import { reportApi } from "../../api-interface";


export const getUnassignedLicenseReportsKey = 'get-unassigned-reports-license-reports-key'

export function useGetUnassignedLicenseReports(
    options: UseQueryOptions<ApiReport[]> = {}
) {
    const licenseId = useRecoilValue(licenseAtom)
    return useQuery(
        [getUnassignedLicenseReportsKey, licenseId],
        () => reportApi.getLicenseReports(licenseId, 'true'),
        {
            ...options,
            enabled:
                typeof options.enabled === 'boolean' ? options.enabled : !!licenseId
        }
    )
}