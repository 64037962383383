import { CircularProgress } from '@mui/material'

export function FullPageCentered(
  props: React.HtmlHTMLAttributes<HTMLDivElement>
) {
  return (
    <div
      {...props}
      style={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    />
  )
}

type FullPageLoaderProps = {
  sx?: React.CSSProperties
}

export function FullPageLoader(props: FullPageLoaderProps) {
  return (
    <FullPageCentered style={props.sx}>
      <CircularProgress />
    </FullPageCentered>
  )
}
