import { Switch, Route, useRouteMatch } from 'react-router-dom'

import { Dashboard } from './dashboard'
import { ReportGroupView } from './report-group-view/report-group-view'

export function DashboardPage() {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/report-groups/:id`}>
        <ReportGroupView />
      </Route>
      <Route>
        <Dashboard />
      </Route>
    </Switch>
  )
}
