import {useQuery} from 'react-query'
import {useRecoilValue} from 'recoil'
import {reportApi} from '../../api-interface'
import {licenseAtom} from '../../state'

export const getDashboardKey = 'get-dashboard-key'

export function useGetDashboard() {
  const licenseId = useRecoilValue(licenseAtom)
  return useQuery(
    [getDashboardKey, licenseId],
    () => reportApi.getDashboard(licenseId),
    {
      enabled: !!licenseId,
    }
  )
}
