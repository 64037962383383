import { useMutation, useQueryClient } from "react-query"
import { HomepageCards, ReportGroup } from "../../models"
import { useRecoilValue } from "recoil"
import { licenseAtom } from "../../state"
import { reportGroupApi } from "../../api-interface"
import { getReportGroupsKey } from "../queries/useGetReportGroups"
import { getDashboardKey } from "../queries"


type DeleteReportGroupContext = {
    previousReportGroups: ReportGroup[]
}

export function useDeleteReportGroup() {
    const queryClient = useQueryClient()
    const licenseId = useRecoilValue(licenseAtom)
    return useMutation(
        (reportGroupId: string) => reportGroupApi.deleteReportGroup(reportGroupId, licenseId),
        {
            async onMutate(reportGroupId: string): Promise<DeleteReportGroupContext> {
                await queryClient.cancelQueries([getReportGroupsKey, licenseId])
                await queryClient.cancelQueries([getDashboardKey, licenseId])

                let previousReportGroups: ReportGroup[] = queryClient.getQueryData([
                    getReportGroupsKey,
                    licenseId,
                ])

                queryClient.setQueryData([getReportGroupsKey, licenseId], (old: ReportGroup[] | undefined) => 
                    (old ?? []).filter((reportGroup: ReportGroup) => reportGroup.id !== reportGroupId)
                )

                queryClient.setQueryData([getDashboardKey, licenseId], (old: HomepageCards) => {
                    const newGroups = old?.reportGroups?.filter((reportGroup: ReportGroup) => reportGroup.id !== reportGroupId)

                    return {
                        reportGroups: newGroups,
                        reports: old?.reports || [],
                    }
                })

                return {previousReportGroups}
            },
            onError(_err, _deletedReportGroup, context: DeleteReportGroupContext) {
                queryClient.setQueryData(
                    [getReportGroupsKey, licenseId],
                    context.previousReportGroups
                )
            },
        }
    )
}