import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  Theme,
  Typography,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { useState } from 'react'
import {
  useGetReportGroupMembers,
  useRemoveReportGroupMember,
} from '../../../../hooks'
import { SearchAndPage } from '../../shared/existing-items/search-and-page'
import { NoResultsText } from '../../../../components'
import { LinkedItemChip, LinkedItemChips } from '../../shared'
import { ApiReport, ReportGroup } from '../../../../models'

const PAGE_SIZE = 10

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    loaderContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
)

export function LinkedReports(props: LinkedReportsProps) {
  const { reportGroup } = props
  const classes = useStyles()
  const [reportSearchQuery, setReportSearchQuery] = useState('')
  const [reportPage, setReportPage] = useState(1)

  const { mutate: removeReport } = useRemoveReportGroupMember()
  const { data: linkedReports, isLoading: loadingReportGroups } =
    useGetReportGroupMembers(reportGroup?.id)

  function handleDelete(report: ApiReport) {
    removeReport({ reportGroupId: reportGroup?.id, report })
  }

  const filteredReports =
    linkedReports?.filter(report =>
      report?.id.includes(reportSearchQuery.toLowerCase())
    ) || []

  const reportsTotalPages = Math.ceil(filteredReports?.length / PAGE_SIZE)

  const paginatedReports = filteredReports.slice(
    (reportPage - 1) * PAGE_SIZE,
    reportPage * PAGE_SIZE
  )

  function handleReportNextPage() {
    setReportPage(prevPage => prevPage + 1)
  }

  function handleReportPrevPage() {
    setReportPage(prevPage => Math.max(prevPage - 1, 1))
  }

  if (loadingReportGroups) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    )
  }

  const handleReportSearch = searchText => {
    setReportSearchQuery(searchText)
    setReportPage(1)
  }

  const renderReportSearchAndPage = () => {
    return (
      <SearchAndPage
        show={linkedReports?.length > 0}
        pageNumber={reportPage}
        totalPagesAvailable={reportsTotalPages}
        placeHolderText='Search Reports'
        onSearch={handleReportSearch}
        onPrevPage={handleReportPrevPage}
        onNextPage={handleReportNextPage}
      />
    )
  }

  return (
    <Stack
      justifyContent='space-between'
      direction='column'
      sx={{ width: '100%' }}
    >
      <Box sx={{ px: 2, mt: 1.5 }}>
        <Typography variant='subtitle1' sx={{ fontWeight: 700 }}>
          Reports
        </Typography>
        <Divider sx={{ mb: 1 }} />
        {renderReportSearchAndPage()}
        {linkedReports?.length === 0 ? (
          <NoResultsText>This group has no linked reports</NoResultsText>
        ) : (
          <LinkedItemChips>
            {paginatedReports.map(report => (
              <LinkedItemChip
                key={report?.id}
                label={`${report.name || report.name}`}
                onDelete={() => handleDelete(report)}
              />
            ))}
          </LinkedItemChips>
        )}
      </Box>
    </Stack>
  )
}

export type LinkedReportsProps = {
  reportGroup: ReportGroup
}
