import { useMutation, useQueryClient } from 'react-query'
import { reportApi } from '../../api-interface'
import { getReportsKey } from '../queries'
import { useRecoilValue } from 'recoil'
import { licenseAtom } from '../../state'
import { AllReports } from '../../models'

type UpdateReportSettingsPayload = {
  id: string
  name: string
  filterPaneEnabled: boolean
  showPageNavigation: boolean
}

type CreateReportContext = {
  previousReports: AllReports
}

export function useUpdateReportSettings() {
  const queryClient = useQueryClient()
  const licenseId = useRecoilValue(licenseAtom)

  return useMutation(
    (payload: UpdateReportSettingsPayload) =>
      reportApi.updateReportSettings({
        id: payload.id,
        displayName: payload.name,
        filterPaneEnabled: payload?.filterPaneEnabled ?? false,
        showPageNavigation: payload?.showPageNavigation ?? false,
        licenseNodeId: licenseId,
      }),
    {
      async onMutate(newReport): Promise<CreateReportContext> {
        await queryClient.cancelQueries([getReportsKey, licenseId])

        let previousReports: AllReports = await queryClient.getQueryData([
          getReportsKey,
          licenseId,
        ])

        queryClient.setQueryData(
          [getReportsKey, licenseId],
          (old: AllReports) => {
            if (!old) {
              return {
                ownedReports: [],
                sharedReports: [],
                defaultReports: [],
                licenseReports: [],
              }
            }
            const ownedReports = old.ownedReports.map(report => {
              if (report.id === newReport.id) {
                return {
                  ...report,
                  filterPaneEnabled: newReport.filterPaneEnabled,
                  showPageNavigation: newReport.showPageNavigation,
                }
              }
              return report
            })

            return {
              ownedReports: ownedReports,
              sharedReports: old.sharedReports,
              defaultReports: old.defaultReports,
              licenseReports: old.licenseReports,
            }
          }
        )

        // Return the context passed on onError
        return { previousReports }
      },
      onError(err, newUser, context: CreateReportContext) {
        queryClient.setQueryData(
          [getReportsKey, licenseId],
          context.previousReports
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getReportsKey, licenseId])
      },
    }
  )
}
