import { useEffect, useState } from 'react'
import {
  useGetDashboard,
  useGetLicense,
  useGetFolders,
  useGetReports,
} from '../../hooks'
import { AllReports, Folder, getLicenseState, ReportGroup } from '../../models'
import { Layout } from '../../components'
import { Stack, Typography } from '@mui/material'
import { Folders, FolderSkeletons } from '../reports/report-dashboard/folders'
import {
  ReportTiles,
  ReportTilesSkeleton,
} from '../reports/report-dashboard/report-tiles'

export function Dashboard() {
  const { data: license } = useGetLicense()
  const { data: homepageCards, isLoading: homepageLoading } = useGetDashboard()
  const { data: reports } = useGetReports()

  const myFolderReports = reports?.ownedReports || []
  const sharedFolderReports = reports?.sharedReports || []

  const { data: folders, isLoading: foldersLoading } = useGetFolders()

  const [skeletonNumber, setSkeletonNumber] = useState(6)
  const [foldersSkeletonNumber, setFoldersSkeletonNumber] = useState(2)
  // const [homeReports, setHomeReports] = useState<AllReports | null>(null)
  const [foldersWithAutoGeneratedReports, setFoldersWithAutoGeneratedReports] =
    useState<Folder[]>([])

  const myReportGroups: ReportGroup[] =
    homepageCards?.reportGroups.filter(
      (group: ReportGroup) => group.showOnHome
    ) || []

  const homepageReports: AllReports = homepageCards?.reports || {
    ownedReports: [],
    sharedReports: [],
    groupedReports: [],
    licenseReports: [],
    defaultReports: [],
  }

  const {
    ownedReports,
    sharedReports,
    groupedReports,
    licenseReports,
    defaultReports,
  } = homepageReports
  const allHomepageReports = [
    ...ownedReports,
    ...sharedReports,
    ...groupedReports,
    ...licenseReports,
    ...defaultReports,
  ]
  const allCards = [
    ...myReportGroups.map(group => ({
      ...group,
      type: 'report-group',
    })),
    ...allHomepageReports,
  ]

  // Check License validity
  const validLicense = getLicenseState(license).valid
  // const setBookmarkState = useSetRecoilState(bookmarkAtom)

  useEffect(() => {
    //Check if we have the number of cards in local storage
    const cardsInLocalStorage = localStorage.getItem('dashboardCards')
    if (cardsInLocalStorage) {
      setSkeletonNumber(parseInt(cardsInLocalStorage))
    }
    //Check if we have the number of reports in local storage
    const foldersInLocalStorage = localStorage.getItem('folders')
    if (foldersInLocalStorage) {
      setFoldersSkeletonNumber(parseInt(foldersInLocalStorage))
    }
  }, [])

  useEffect(() => {
    if (homepageLoading || !allCards || !allCards.length || foldersLoading) {
      return
    }
    localStorage.setItem('dashboardCards', JSON.stringify(allCards.length))
    localStorage.setItem('folders', JSON.stringify(folders?.length))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homepageLoading, foldersLoading])

  useEffect(() => {
    if (foldersLoading || !folders) return

    const myReportsFolder: Folder = {
      id: 'my-reports-folder',
      name: 'My Reports',
      items: myFolderReports,
      disableEdit: true,
    }

    const sharedReportsFolder: Folder = {
      id: 'shared-reports-folder',
      name: 'Shared Reports',
      items: sharedFolderReports,
      disableEdit: true,
    }

    const showSharedReports = sharedReportsFolder.items?.length > 0
    const showMyReports = myReportsFolder.items?.length > 0

    localStorage.setItem(
      'folders',
      JSON.stringify(
        folders.length + (showSharedReports ? 1 : 0) + (showMyReports ? 1 : 0)
      )
    )

    setFoldersWithAutoGeneratedReports(
      [
        ...folders,
        showMyReports ? myReportsFolder : null,
        showSharedReports ? sharedReportsFolder : null,
      ].filter(Boolean) as Folder[]
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folders])

  // // We may not want to do the following here.  It might be better to have the user always see the dashboard.
  // // We are done loading
  // if (isOnlyOneUniqueItem(allCards.map(c => c.id)) && validLicense) {
  //   return (
  //     // TODO: Modify this to navigate to reportGroup or reports
  //     <Redirect
  //       to={routes.reportGroups.group({
  //         id: allCards[0].id,
  //       })}
  //     />
  //   )
  // }

  return (
    <Layout>
      <Stack
        sx={{
          m: 'auto',
          mb: 8,
          maxWidth: '1280px',
          px: { xs: 3.5, sm: 4.5 },
        }}
        alignItems='center'
      >
        {!validLicense && (
          <>
            <Typography sx={{ mt: 10 }} variant='h6'>
              {`Your License expired on: ${license.subscriptionEndDate}. Please contact your license owner to reactivate the license.`}
            </Typography>
          </>
        )}
        {validLicense && (
          <>
            {allCards.length === 0 && !homepageLoading && (
              <Typography sx={{ mt: 10 }} variant='h6'>
                No items to display
              </Typography>
            )}
            {homepageLoading ? (
              <ReportTilesSkeleton numReports={skeletonNumber} title={''} />
            ) : (
              <>
                {allCards.length > 0 && (
                  <ReportTiles
                    title='My Report Groups'
                    isLoading={homepageLoading}
                    cards={allCards}
                    folders={foldersWithAutoGeneratedReports}
                  />
                )}
              </>
            )}

            {foldersLoading ? (
              <FolderSkeletons numFolders={foldersSkeletonNumber} />
            ) : (
              <Folders folders={foldersWithAutoGeneratedReports} />
            )}
          </>
        )}
      </Stack>
    </Layout>
  )
}
