import React, { useState, useRef } from 'react'

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'

import 'react-image-crop/dist/ReactCrop.css'
import { Button, Theme, alpha, Typography } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addLogoBox: {
      height: '300px',
      width: '300px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f2f2f2',
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
      },
    },
    secondaryColor: {
      color: theme.palette.secondary.main,
    },
    imagePreviewer: {
      height: '200px',
      width: 'auto',
    },
  })
)

type ImageEditorProps = {
  label: string
  handleLogoChange: (logo: HTMLCanvasElement, image: HTMLImageElement) => void
  backgroundColor: string
  preview: {
    height: string
    width: string
    borderRadius?: string
    border?: string
  }
  aspect?: number
}

export function ImageEditor(props: ImageEditorProps) {
  const classes = useStyles()
  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  // const bannerAspect1 = 3 / 2
  // const bannerAspect2 = 21 / 9
  // const [aspect, setAspect] = useState(bannerAspect2)
  const { label, handleLogoChange, backgroundColor, preview, aspect } = props

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setImgSrc(reader.result?.toString() || '')
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget
    setCrop(centerAspectCrop(width, height, aspect || 16 / 9))
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop)
      }
    },
    100,
    [completedCrop]
  )

  // const handleAspectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const checked = e.target.checked
  //   let newWidth = checked ? bannerAspect1 * 100 : bannerAspect2 * 100
  //   if (newWidth > 290) {
  //     // Max width of the image previewer.
  //     newWidth = 290
  //   }

  //   const newCrop: Crop = {
  //     x: 5,
  //     y: 5,
  //     width: newWidth,
  //     height: 100,
  //     unit: 'px',
  //   }

  //   if (e.target.checked) {
  //     setAspect(bannerAspect1)
  //   } else {
  //     setAspect(bannerAspect2)
  //   }
  //   setCrop(newCrop)
  // }

  if (!crop) {
    setCrop({
      unit: '%',
      width: 90,
      height: 90,
      x: 5,
      y: 5,
    })
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: 'auto',
        flexDirection: 'column',
      }}
    >
      {/* {imgSrc && (
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Crop169Icon />
          <Switch
            defaultChecked={false}
            onChange={e => handleAspectChange(e)}
          />
          <Crop75Icon />
        </div>
      )} */}
      {!imgSrc && (
        <>
          <Button className={classes.addLogoBox} component='label'>
            <Typography style={{ color: backgroundColor }} fontSize={45}>
              +
            </Typography>
            <input
              type='file'
              onChange={event => {
                onSelectFile(event)
              }}
              hidden
              accept='image/png, image/svg+xml, image/svg'
            />
          </Button>
          <Typography
            variant='body2'
            align='center'
            fontStyle='italic'
            style={{ marginTop: 5 }}
          >
            File must be a PNG, or SVG.
          </Typography>
        </>
      )}
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ height: 'auto', width: '300px' }}>
          {!!imgSrc && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={c => setCompletedCrop(c)}
              // maxHeight={crop?.width * 2}
              // minHeight={crop?.width / 2}
              aspect={aspect}
            >
              <img ref={imgRef} alt='Logo' src={imgSrc} onLoad={onImageLoad} />
            </ReactCrop>
          )}
        </div>

        {!!completedCrop && imgSrc && (
          <div
            style={{
              height: preview.height,
              width: preview.width,
              borderRadius: preview.borderRadius,
              border: preview.border,
              padding: 7,
              marginLeft: 7,
              backgroundColor: backgroundColor,
              overflow: 'hidden',
            }}
            className={classes.imagePreviewer}
          >
            <canvas
              ref={previewCanvasRef}
              style={{
                objectFit: 'contain',
                width: '100%',
                height: '100%',
                borderRadius: preview.borderRadius,
              }}
            />
            <Typography fontSize={10} fontStyle='italic'>
              Preview of {label}
            </Typography>
          </div>
        )}
      </div>

      {imgSrc && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 5,
          }}
        >
          <Button
            className={classes.secondaryColor}
            onClick={() => setImgSrc('')}
          >
            Remove
          </Button>
          <Button
            variant='outlined'
            onClick={() => {
              handleLogoChange(previewCanvasRef.current, imgRef.current)
            }}
          >
            {`Save ${label}`}
          </Button>
        </div>
      )}
    </div>
  )
}

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
export function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}
