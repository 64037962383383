import {
  Collapse,
  Divider,
  Icon,
  IconButton,
  Popover,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { Folder, FolderItem } from '../../../models'
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import { useState } from 'react'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { DragDropContext, Draggable } from 'react-beautiful-dnd'
import { StrictModeDroppable } from '../create-custom-report/custom-report-table/strict-mode-dropable'
import { useUpdateFolder } from '../../../hooks/mutations/useUpdateFolder'
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined' // Paginated report icon
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined' //Custom report builder icon
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined' // Regular report icon
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ManageFolderDialog from './manage-folder-dialog'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'

export function FolderSkeletons(props: { numFolders: number }) {
  const { numFolders } = props

  const folderSpacing = numFolders > 2 ? 3 : numFolders > 1 ? 2 : 1

  return (
    <Stack
      direction='row'
      gap={6}
      sx={{
        whiteSpace: 'wrap',
        flexWrap: 'wrap',
        overflow: 'auto',
        width: '100%',
        mt: 8,
      }}
    >
      {[...Array(numFolders)].map((_, index) => (
        <Skeleton
          key={index}
          variant='rectangular'
          sx={{
            width: `calc(100% / ${folderSpacing} - ${folderSpacing === 1 ? 0 : 32}px)`,
            minWidth: '340px',
            borderRadius: '8px',
            height: '40px',
          }}
        />
      ))}
    </Stack>
  )
}


type FoldersProps = {
  folders: Folder[] //fixme
}

export function Folders(props: FoldersProps) {
  const { folders } = props
  return (
    <Stack
      direction='row'
      gap={6}
      sx={{
        whiteSpace: 'wrap',
        flexWrap: 'wrap',
        overflow: 'auto',
        width: '100%',
        mt: 8,
      }}
    >
      {folders?.map((folder, index) => (
        <SingleFolder
          key={folder.id + index}
          folder={folder}
          numFolders={folders?.length}
        />
      ))}
    </Stack>
  )
}

type FolderProps = {
  folder: Folder
  numFolders: number
}

export function SingleFolder(props: FolderProps) {
  const { folder, numFolders } = props
  const { name, items, id, disableEdit } = folder

  const { mutateAsync: updateFolder } = useUpdateFolder()

  const handleDragEnd = async (result: any) => {
    if (!result.destination) return
    const sortableItems = Array.from(items)
    const [reorderedItem] = sortableItems.splice(result.source.index, 1)
    sortableItems.splice(result.destination.index, 0, reorderedItem)
    await updateFolder({ items: sortableItems, folderId: id })
  }

  const onRemoveFolderItem = async (itemId: string) => {
    const newItems = items.filter(item => item.id !== itemId)
    await updateFolder({ items: newItems, folderId: id })
  }

  const [isExpanded, setIsExpanded] = useState(items?.length > 0)
  const [openFolderSettings, setOpenFolderSettings] = useState(false)

  const folderSpacing = numFolders > 2 ? 3 : numFolders > 1 ? 2 : 1

  
  return (
    <Stack
      direction={'column'}
      sx={{
        width: `calc(100% / ${folderSpacing} - ${folderSpacing === 1 ? 0 : 32}px)`,
        minWidth: '340px',
        borderRadius: '8px',
        backgroundColor: '#f0f4f8',
        height: '100%',
        '&:hover': {
          backgroundColor: !isExpanded && '#dce2e8',
        },
        maxWidth: '880px',
      }}
      onClick={() => {
        if (!isExpanded) setIsExpanded(true)
      }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        gap={1}
        sx={{
          p: 1,
        }}
      >
        <Typography fontSize={'12px'} fontWeight={'bold'}>
          {name}
        </Typography>
        <Stack direction={'row'} alignItems={'center'}>
          {isExpanded && !disableEdit &&
            (
            <Tooltip title={'Edit folder'} placement={'top'} arrow>
              <Icon
                sx={{
                  cursor: 'pointer',
                  ml: 1,
                  '&:hover': {
                    color: 'rgba(0, 0, 0, 0.5)',
                  },
                }}
                onClick={() => setOpenFolderSettings(true)}
              >
                <EditOutlinedIcon sx={{ fontSize: '16px', mb: '4px' }} />
              </Icon>
            </Tooltip>
          )}
          <Icon
            sx={{
              cursor: 'pointer',
              ml: 1,
              '&:hover': {
                color: 'rgba(0, 0, 0, 0.5)',
              },
            }}
          >
            {isExpanded ? (
              <ArrowDropUpOutlinedIcon
                onClick={() => setIsExpanded(!isExpanded)}
              />
            ) : (
              <ArrowDropDownOutlinedIcon
                onClick={() => setIsExpanded(!isExpanded)}
              />
            )}
          </Icon>
        </Stack>
      </Stack>

      <Collapse in={isExpanded} easing={'ease-in-out'}>
        <Divider
          sx={{
            mx: 1,
          }}
        />
        <DragDropContext onDragEnd={handleDragEnd}>
          <StrictModeDroppable droppableId='droppable'>
            {provided => (
              <Stack
                direction='column'
                gap={2}
                sx={{
                  p: 1,
                  my: 1,
                  minWidth: '340px',
                }}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {items?.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id + index}
                    index={index}
                  >
                    {provided => (
                      <SingleFolderItem
                        item={item}
                        provided={provided}
                        onRemove={() => {
                          onRemoveFolderItem(item.id)
                        }}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Stack>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </Collapse>
      {openFolderSettings && (
        <ManageFolderDialog
          folder={folder}
          open={openFolderSettings}
          onClose={() => setOpenFolderSettings(false)}
        />
      )}
    </Stack>
  )
}

type FolderItemProps = {
  item: FolderItem
  provided: any
  onRemove: () => void
}

function getItemIconByType(type: string) {
  if (type === 'custom') {
    return <DashboardCustomizeOutlinedIcon sx={{ fontSize: '14px', mb: 0.5 }} />
  }
  if (type === 'paginated') {
    return <TableChartOutlinedIcon sx={{ fontSize: '14px', mb: 0.5 }} />
  }
  if (type === 'bookmark') {
    return <BookmarkBorderIcon sx={{ fontSize: '14px', mb: 0.5 }} />
  }
  return <BarChartOutlinedIcon sx={{ fontSize: '14px', mb: 0.5 }} />
}

export function SingleFolderItem(props: FolderItemProps) {
  const { item, provided, onRemove } = props

  const [isHovered, setIsHovered] = useState(false)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleMoreClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const onItemClicked = () => {
    //set URL based on item type
    if (item.type === 'bookmark' && item.reportId) {
      window.location.href = `/reports/${item.reportId}/${item.id}`
      return
    }

    window.location.href = `/reports/${item.id}`
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent={'space-between'}
      gap={1}
      sx={{
        borderRadius: '8px',
        py: 0.5,
        px: 1,
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
        cursor: 'pointer',
      }}
      onClick={onItemClicked}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      ref={provided.innerRef}
      {...provided.draggableProps}
    >
      <Stack direction='row' alignItems={'center'} gap={1}>
        <Icon
          {...provided.dragHandleProps}
          sx={{
            cursor: 'grab',
          }}
        >
          {getItemIconByType(item.type)}
        </Icon>
        <Typography fontSize={'14px'}>{item.name}</Typography>
      </Stack>
      {isHovered && (
        <IconButton
          onClick={e => {
            e.stopPropagation()
            handleMoreClicked(e)
          }}
          sx={{
            cursor: 'pointer',
            height: '24px',
            width: '24px',
            '&:hover': {
              color: theme => theme.palette.primary.light,
            },
          }}
        >
          <MoreHorizOutlinedIcon />
        </IconButton>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: { sx: { borderRadius: '8px' } },
        }}
      >
        <Stack
          sx={{
            p: 1,
            borderRadius: '8px',
            backgroundColor: '#f0f4f8',
            cursor: 'pointer',
            '&:hover': {
              color: theme => theme.palette.primary.light,
            },
          }}
          direction={'row'}
          onClick={e => {
            e.stopPropagation()
            onRemove()
            handlePopoverClose()
          }}
          spacing={1}
        >
          <DeleteOutlineOutlinedIcon
            sx={{
              mt: '2px',
              fontSize: '20px',
            }}
          />
          <Typography>Delete</Typography>
        </Stack>
      </Popover>
    </Stack>
  )
}
