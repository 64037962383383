import { AxiosError } from 'axios'
import {
  ReportToCreate,
  ReportConnectionStatus,
  ApiReport,
  GetReportViewersResponse,
  ApiGroupReport,
  ReportSettingsToUpdate,
  ReportPage,
  NewReportEmbedRequest,
  PaginatedReportToCreate,
  ReportToUpdate,
  HomepageCards,
  ReportGroup,
} from '../models'
import { getAxios, endpoints, getUrl, formatReports } from './util'

export const reportApi = {
  async createReport(report: ReportToCreate) {
    let axios = await getAxios()

    const response = await axios({
      method: 'post',
      url: endpoints.reports,
      data: report,
    })

    return response.data
  },

  async embedAccess(request: NewReportEmbedRequest) {
    let axios = await getAxios()
    let response = await axios({
      method: 'post',
      url: endpoints.embedAccess,
      data: {
        licenseId: request.licenseId,
        pbiReportId: request.pbiReportId,
        appRegNodeId: request.appRegNodeId,
        appClientId: request.appClientId,
        appRegTenantId: request.appRegTenantId,
        pbiWorkspaceId: request.workspaceId,
        profileId: request.profileId,
        rlsRoles: request.rlsRoles,
        username: request.username,
      },
    })

    return response?.data?.payload
  },

  async createPaginatedReport(report: PaginatedReportToCreate) {
    let axios = await getAxios()

    const response = await axios({
      method: 'post',
      url: endpoints.importCustomReport,
      data: report,
    })

    return response.data
  },

  async updatePaginatedReport(report: PaginatedReportToCreate) {
    let axios = await getAxios()

    const response = await axios({
      method: 'patch',
      url: endpoints.importCustomReport,
      data: report,
    })

    return response.data
  },

  async getReportPages(
    licenseId: string,
    appRegNodeId: string,
    appClientId: string,
    appRegTenantId: string,
    reportId: string,
    groupId: string
  ): Promise<ReportPage[]> {
    if (!licenseId || !appRegNodeId || !reportId || !groupId) return []
    let axios = await getAxios()

    let response = await axios({
      method: 'get',
      url: getUrl(endpoints.reportPages, {
        licenseId,
        appRegNodeId,
        appClientId,
        appRegTenantId,
        reportId,
        groupId,
      }),
    })

    return response.data?.payload || []
  },

  async updateReportSettings(reportSettings: ReportSettingsToUpdate) {
    const {
      id,
      licenseNodeId,
      displayName,
      filterPaneEnabled,
      showPageNavigation,
    } = reportSettings
    let axios = await getAxios()

    await axios({
      method: 'put',
      url: endpoints.reports,
      data: {
        id,
        displayName,
        filterPaneEnabled,
        showPageNavigation,
        licenseNodeId,
      },
    })
  },

  async updateReport(report: ReportToUpdate) {
    let axios = await getAxios()

    await axios({
      method: 'patch',
      url: endpoints.reports,
      data: report,
    })
  },

  async getReports(licenseId: string, reportIds?: string[]) {
    let axios = await getAxios()

    // Note: If we want to request multiple users, the ids have to be sent in comma separated.

    let response
    try {
      response = await axios({
        method: 'get',
        url: getUrl(endpoints.reports, {
          licenseId,
          reportIds: reportIds?.join(',') || undefined,
        }),
      })
    } catch (e) {
      let error: AxiosError = e

      if (error.response) {
        // TODO
        // let status = e.response.status
      }
      return {
        ownedReports: [],
        sharedReports: [],
        reportGroupReports: [],
        defaultReports: [],
      }
    }

    const ownedReports: ApiReport[] = formatReports(response.data.ownedReports)
    const sharedReports: ApiReport[] = formatReports(
      response.data.sharedReports
    )
    const reportGroupReports: ApiReport[] = formatReports(
      response.data.reportGroupReports
    )
    const defaultReports: ApiReport[] = formatReports(
      response.data.defaultReports
    )
    const licenseReports: ApiReport[] = formatReports(
      response.data.licenseReports
    )
    return {
      ownedReports,
      sharedReports,
      reportGroupReports,
      defaultReports,
      licenseReports,
    }
  },

  async getLicenseReports(
    licenseId: string,
    onlyUnassigned: string
  ): Promise<ApiReport[]> {
    let axios = await getAxios()
    let response
    try {
      response = await axios({
        method: 'get',
        url: getUrl(endpoints.licenseReports, { licenseId, onlyUnassigned }),
      })

      return response?.data?.licenseReports
    } catch (e) {
      return []
    }
  },

  async getReportsByUser(
    licenseId: string,
    userId: string
  ): Promise<{
    reportsFromGroups: ApiGroupReport[]
    reportsFromUser: ApiReport[]
  }> {
    let axios = await getAxios()

    let response = await axios({
      method: 'get',
      url: getUrl(endpoints.userReports, { licenseId, userId }),
    })

    const allReportsResponse = response.data

    if (!allReportsResponse.success) {
      return { reportsFromGroups: [], reportsFromUser: [] }
    }

    return {
      reportsFromGroups:
        (allReportsResponse.reportsFromGroups as ApiGroupReport[]) || [],
      reportsFromUser:
        (allReportsResponse.reportsFromUser as ApiReport[]) || [],
    }
  },

  async deleteReport(reportId: string) {
    let deleteData = {
      reportId: reportId,
    }
    let axios = await getAxios()
    await axios({
      method: 'delete',
      url: endpoints.reports,
      data: deleteData,
    })
    return reportId
  },

  async cloneReport({
    sourceReportId,
    newPbiReportId,
    newName,
    licenseNodeId,
  }): Promise<ReportToCreate> {
    let cloneData = {
      sourceReportId: sourceReportId,
      newPbiReportId: newPbiReportId,
      newName: newName,
      licenseNodeId: licenseNodeId,
    }
    let axios = await getAxios()
    let response = await axios({
      method: 'post',
      url: endpoints.workspaceReports,
      data: cloneData,
    })
    return response.data
  },

  async testReportConnection(
    pbiWorkspaceId: string,
    pbiReportId: string,
    appRegistrationNodeId: string,
    profileId: string
  ): Promise<ReportConnectionStatus> {
    let axios = await getAxios()

    try {
      let response = await axios({
        method: 'post',
        url: endpoints.testConnection,
        data: {
          pbiWorkspaceId,
          pbiReportId,
          appRegistrationNodeId,
          profileId,
        },
      })

      if (
        (response.status === 200 &&
          response.data.isEffectiveIdentityRequired) ||
        response.data.isEffectiveIdentityRolesRequired
      ) {
        return ReportConnectionStatus.RowLevelSecurity
      }
      return response.status
    } catch (e) {
      return e.response.status
    }
  },

  async getViewers(
    reportId: string,
    licenseId: string
  ): Promise<GetReportViewersResponse> {
    const axios = await getAxios()

    const response = await axios.get<{ payload: GetReportViewersResponse }>(
      getUrl(endpoints.reportViewers, {
        reportId,
        licenseId,
      })
    )

    return response.data.payload
  },

  async addViewers(
    reportId: string,
    viewerIds: string[],
    licenseId: string
  ): Promise<void> {
    let axios = await getAxios()

    let response = await axios({
      method: 'post',
      url: endpoints.reportViewers,
      data: {
        reportId,
        viewerIds,
        licenseId,
      },
    })

    const { success, errorMessage } = response.data
    if (!success) throw new Error(errorMessage)
  },

  async addViewerToReports(
    reportIds: string[],
    viewerId: string,
    licenseId: string
  ): Promise<void> {
    let axios = await getAxios()

    let response = await axios({
      method: 'put',
      url: endpoints.reportViewers,
      data: {
        reportIds,
        viewerId,
        licenseId,
      },
    })

    const { success, errorMessage } = response.data

    if (!success) throw new Error(errorMessage)
  },

  async removeViewers(
    reportId: string,
    viewerId: string,
    licenseId: string
  ): Promise<void> {
    let axios = await getAxios()

    let response = await axios({
      method: 'delete',
      url: endpoints.reportViewers,
      data: {
        reportId,
        viewerId,
        licenseId,
      },
    })

    const { success, errorMessage } = response.data
    if (!success) throw new Error(errorMessage)
  },

  async addDefault(reportId: string, licenseId: string): Promise<void> {
    let axios = await getAxios()

    let response = await axios({
      method: 'post',
      url: endpoints.reportDefault,
      data: {
        reportId: reportId,
        licenseId: licenseId,
      },
    })

    const { success, errorMessage } = response.data
    if (!success) throw new Error(errorMessage)
  },
  async removeDefault(reportId: string, licenseId: string): Promise<void> {
    let axios = await getAxios()

    let response = await axios({
      method: 'delete',
      url: endpoints.reportDefault,
      data: {
        reportId: reportId,
        licenseId: licenseId,
      },
    })

    const { success, errorMessage } = response.data
    if (!success) throw new Error(errorMessage)
  },

  async getDashboard(licenseId: string): Promise<HomepageCards> {
    let axios = await getAxios()

    let response = await axios({
      method: 'get',
      url: getUrl(endpoints.dashboard, {
        licenseId,
      }),
    })

    const reportGroups: ReportGroup[] = response.data.reportGroups.map(
      reportGroup => {
        const { showOnHome, ...rest } = reportGroup
        return {
          showOnHome: showOnHome === 'true',
          ...rest,
        }
      }
    )

    return {
      reportGroups,
      reports: response.data.reports,
    }
  },
}
