import { Box, Typography } from '@mui/material'

type ErrorPageProps = {
  children: string
}

export function ErrorPage(props: ErrorPageProps) {
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      height='100%'
      width='100%'
    >
      <Typography variant='h6' color='error' sx={{ mt: 20 }}>
        {props.children}
      </Typography>
    </Box>
  )
}
