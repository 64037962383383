import {Alert, Collapse, IconButton, Link, Typography} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {FC, useEffect, useState} from 'react'
import {useGetLicense} from '../../hooks'
import {routes} from '../../routes'

export const NotificationAlert: FC = () => {
  const {data: license} = useGetLicense()

  const licenseExpired = new Date(license?.subscriptionEndDate) < new Date()
  const [showNotification, setShowNotification] = useState<boolean>(false)

  useEffect(() => {
    
    const show =
      license?.isOwner &&
      license?.subscriptionStatus &&
      ['SUSPENDED', 'CANCELLED'].includes(license?.subscriptionStatus)
    setShowNotification(show)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [license])

  if(!license) return null
  
  return (
    <Collapse in={showNotification}>
      <Alert
        severity='error'
        variant='filled'
        action={
          <IconButton
            aria-label='close'
            color='inherit'
            size='small'
            onClick={() => {
              setShowNotification(false)
            }}
          >
            <CloseIcon fontSize='inherit' />
          </IconButton>
        }
      >
        <Typography>
          {`Your license has been ${license.subscriptionStatus?.toLocaleLowerCase()} and ${
            licenseExpired ? 'expired on' : 'will expire on'
          } ${license.subscriptionEndDate}. `}
          {license.subscriptionStatus === 'SUSPENDED' && (
            <>
              Please visit your{' '}
              <Link
                href={routes.admin.subscription}
                sx={{color: 'inherit', textDecorationColor: 'inherit'}}
              >
                subscriptions
              </Link>{' '}
              to reactivate{' '}
              {licenseExpired ? 'your license subscription' : 'before expiry'}.
            </>
          )}
        </Typography>
      </Alert>
    </Collapse>
  )
}
