import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { ReportGroupView } from './report-group-view/report-group-view'

export function ReportGroupsPage() {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/:id`}>
        <ReportGroupView />
      </Route>
    </Switch>
  )
}
