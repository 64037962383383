import { useRecoilValue } from 'recoil'
import { licenseAtom } from '../../state'
import { useMutation, useQueryClient } from 'react-query'
import { reportGroupApi } from '../../api-interface'
import {
  getReportGroupMembersKey,
  getUnassignedLicenseReportsKey,
} from '../queries'
import { ApiReport } from '../../models'

export const removeReportGroupMemberKey = 'remove-report-group-member'

export type RemoveReportGroupMemberPayload = {
  reportGroupId: string
  report: ApiReport
}

export function useRemoveReportGroupMember() {
  const licenseId = useRecoilValue(licenseAtom)
  const queryClient = useQueryClient()

  return useMutation(
    (payload: RemoveReportGroupMemberPayload) =>
      reportGroupApi.removeReportGroupMember(
        payload.reportGroupId,
        payload.report.id
      ),
    {
      async onMutate(
        payload: RemoveReportGroupMemberPayload
      ): Promise<ApiReport[]> {
        await queryClient.cancelQueries([
          getReportGroupMembersKey,
          payload.reportGroupId,
        ])
        await queryClient.cancelQueries([
          getUnassignedLicenseReportsKey,
          licenseId,
        ])

        // let prevLicenseReports: ApiReport[] = queryClient.getQueryData([
        //     getUnassignedLicenseReportsKey,
        //     licenseId
        // ])

        queryClient.setQueryData(
          [getUnassignedLicenseReportsKey, licenseId],
          (old: ApiReport[]) => {
            const updatedReports = [...old, payload.report]
            return updatedReports
          }
        )

        let previousReports: ApiReport[] = queryClient.getQueryData([
          getReportGroupMembersKey,
          payload.reportGroupId,
        ])

        let allPrevReports = previousReports

        if (allPrevReports?.length === 0) {
          previousReports = await queryClient.fetchQuery(
            [getReportGroupMembersKey, payload.reportGroupId],
            () => reportGroupApi.getReportGroupMembers(payload.reportGroupId)
          )
          allPrevReports = previousReports
        }

        queryClient.setQueryData(
          [getReportGroupMembersKey, payload.reportGroupId],
          (old: ApiReport[]) => {
            const updatedReports = old?.filter(
              (oldReport: ApiReport) => oldReport.id !== payload.report.id
            )

            return updatedReports
          }
        )

        return allPrevReports
      },
      onError(
        _err,
        payload: RemoveReportGroupMemberPayload,
        previousReports: ApiReport[]
      ) {
        queryClient.setQueryData(
          [getReportGroupMembersKey, payload.reportGroupId],
          previousReports
        )
      },
    }
  )
}
