import { useMutation, useQueryClient } from "react-query"
import { ReportGroup, ReportGroupToCreate } from "../../models"
import { licenseAtom } from "../../state"
import { useRecoilValue } from "recoil"
import { reportGroupApi } from '../../api-interface'
import { getReportGroupsKey } from "../queries/useGetReportGroups"

type CreateReportGroupContext = {
    previousReportGroups: ReportGroup[]
}


export function useCreateReportGroup() {
    const queryClient = useQueryClient()
    const licenseId = useRecoilValue(licenseAtom)

    return useMutation(
        (payload: ReportGroupToCreate) =>
            reportGroupApi.createReportGroup({reportGroup: payload, licenseId}),
        {
            async onMutate(
                newReportGroup: ReportGroupToCreate 
            ): Promise<CreateReportGroupContext> {
                await queryClient.cancelQueries([getReportGroupsKey, licenseId])

                let previousReportGroups: ReportGroup[] = queryClient.getQueryData([
                    getReportGroupsKey,
                    licenseId
                ])

                queryClient.setQueryData([getReportGroupsKey, licenseId], (old: ReportGroup[]) => {
                    if (old)
                        return [
                    ...old,
                    {...newReportGroup},
                ]

                    return old
                })

                return {previousReportGroups}
            },
    
            onError(_err, _newReportGroup, context: CreateReportGroupContext) {
                queryClient.setQueryData(
                    [getReportGroupsKey, licenseId],
                    context.previousReportGroups
                )
            },
            onSettled() {
                queryClient.invalidateQueries([getReportGroupsKey, licenseId])
            }
        }
    )
}