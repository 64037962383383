import {
  useCreateReportGroup,
  useGetReportGroups,
  useUpdateReportGroup,
} from '../../../../hooks'
import { ReportGroup } from '../../../../models'
import { Form, FormField } from '../../shared'
import { useState } from 'react'
import { ContentLoader } from '../../shared/Loader/ContentLoader'
import {
  FormControlLabel,
  Switch,
  Theme,
  Typography,
  useTheme,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { ImageSelector } from '../appearance/image-selector'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    currentImage: {
      padding: theme.spacing(2),
      borderRadius: '5px',
      maxWidth: '175px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
)

export type ReportGroupFromProps = {
  onClose: () => void
  editMode?: boolean
  reportGroup?: ReportGroup
}

const illegalCharsRegex = /[/\\?%*:|"<>.']/g

export const ReportGroupForm = (props: ReportGroupFromProps) => {
  const { onClose, editMode, reportGroup } = props
  const theme = useTheme()
  const classes = useStyles()

  const { mutateAsync: createReportGroup, isLoading: isSaving } =
    useCreateReportGroup()
  const { mutateAsync: updateReportGroup, isLoading: isUpdating } =
    useUpdateReportGroup()
  const { data: currentReportGroups } = useGetReportGroups()

  const reportGroupNames = currentReportGroups?.map(g => g.name.toLowerCase())

  const [formError, setFormError] = useState<string>('')

  const [reportGroupName, setReportGroupName] = useState<string>(
    reportGroup?.name || ''
  )
  const [reportGroupDescription, setReportGroupDescription] = useState<string>(
    reportGroup?.description || ''
  )
  const [reportGroupPermission, setReportGroupPermission] = useState<string>(
    reportGroup?.permissionTitle || ''
  )
  const [showOnHomePage, setShowOnHomePage] = useState<boolean>(
    reportGroup?.showOnHome ?? true
  )
  const toggleShow = () => {
    setShowOnHomePage(o => !o)
  }
  const [image, setImage] = useState<string>(reportGroup?.imageUrl)

  const onReportGroupNameChange = (name: string) => {
    setReportGroupName(name)

    const illegalChars = name.match(illegalCharsRegex)
    if (illegalChars) {
      setFormError(
        'Name cannot contain any of the folloing charachters: / \\ ? % * : | " \' < > .'
      )
      return
    }

    if (
      reportGroupNames?.includes(name.trim().toLowerCase()) &&
      name !== reportGroup?.id.toLowerCase()
    ) {
      setFormError('Report Group Name already exists')
      return
    }

    setFormError('')
  }

  const onPermissionChange = (title: string) => {
    setReportGroupPermission(title)

    const illegalChars = title.match(illegalCharsRegex)
    if (illegalChars) {
      setFormError(
        'Name cannot contain any of the folloing charachters: / \\ ? % * : | " \' < > .'
      )
      return
    }

    setFormError('')
  }

  const onDescriptionChange = (description: string) => {
    setReportGroupDescription(description)

    const illegalChars = description.match(illegalCharsRegex)
    if (illegalChars) {
      setFormError(
        'Name cannot contain any of the folloing charachters: / \\ ? % * : | " \' < > .'
      )
      return
    }
    setFormError('')
  }

  const handleSave = async (): Promise<void> => {
    if (formError) return
    const trimmedGroupName = reportGroupName.trim()

    if (!trimmedGroupName) {
      setFormError('Report Group name is required')
      return
    }

    editMode
      ? await updateReportGroup({
          id: reportGroup.id,
          name: trimmedGroupName,
          description: reportGroupDescription,
          permissionTitle: reportGroupPermission,
          showOnHome: showOnHomePage,
          imageText: image,
        })
      : createReportGroup({
          name: trimmedGroupName,
          description: reportGroupDescription,
          permissionTitle: reportGroupPermission,
          showOnHome: showOnHomePage,
          imageText: image,
        })

    onClose()
  }

  if (isSaving) {
    return <ContentLoader loading={isSaving} />
  }

  return (
    <Form
      error={formError}
      onSave={handleSave}
      onCancel={onClose}
      isSaving={isSaving || isUpdating}
    >
      <FormField
        label='Group Name'
        onTextChange={onReportGroupNameChange}
        value={reportGroupName}
      />
      <FormField
        label='Description'
        onTextChange={onDescriptionChange}
        value={reportGroupDescription}
      />
      <FormField
        label='Permissions Title'
        onTextChange={onPermissionChange}
        value={reportGroupPermission}
      />
      <FormControlLabel
        control={
          <Switch
            checked={showOnHomePage}
            onChange={toggleShow}
            name='showOnHome'
            color='primary'
          />
        }
        label={'Show Report Group on Home Page'}
        labelPlacement='start'
      />
      {image && (
        <div className={classes.currentImage}>
          <img
            src={image}
            alt='report group preview'
            style={{
              width: '150px',
              marginBottom: 2,
              borderRadius: '50%',
              border: '2px solid #333',
              objectFit: 'cover',
            }}
          />
        </div>
      )}

      {!image && (
        <Typography variant='body2' sx={{ m: 2 }} fontStyle='italic'>
          No image set
        </Typography>
      )}
      <ImageSelector
        label='Image'
        setImage={setImage}
        image={image}
        primaryColor='#FFFFFF'
        secondaryColor={theme.palette.secondary.main}
        preview={{
          height: '200px',
          width: '200px',
          borderRadius: '50%',
          border: '2px solid #333',
        }}
        aspect={1}
      />
    </Form>
  )
}
