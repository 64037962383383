import { useMutation, useQueryClient } from "react-query"
import { HomepageCards, ReportGroup, ReportGroupToCreate } from "../../models"
import { useRecoilValue } from "recoil"
import { licenseAtom } from "../../state"
import { reportGroupApi } from "../../api-interface"
import { getReportGroupsKey, getDashboardKey } from "../queries"


type CreateReportGroupContext = {
    previousReportGroups: ReportGroup[]
}

export function useUpdateReportGroup() {
    const queryClient = useQueryClient()
    const licenseId = useRecoilValue(licenseAtom)

    return useMutation(
        (payload: ReportGroupToCreate) => 
            reportGroupApi.editReportGroup({reportGroup: payload, licenseId}),
        {
            async onMutate(
                newGroup: ReportGroupToCreate
            ): Promise<CreateReportGroupContext> {
                await queryClient.cancelQueries([getReportGroupsKey, licenseId])
                await queryClient.cancelQueries([getDashboardKey, licenseId])

                let previousReportGroups: ReportGroup[] = queryClient.getQueryData([
                    getReportGroupsKey,
                    licenseId,
                ])

                queryClient.setQueryData([getReportGroupsKey, licenseId], (old: ReportGroup[]) => 
                    old.map((reportGroup: ReportGroup) => {
                        if (reportGroup.id === newGroup.id) {
                            return {
                                ...newGroup,
                                name: newGroup.name,
                                description: newGroup.description,
                                permissionTitle: newGroup.permissionTitle,
                                showOnHome: newGroup.showOnHome,
                                // Image URL will not be included in the optimistic update since that is created in the back
                            }
                        }
                        return reportGroup
                    })
                )

                // This means when a report group gets updated, it will be removed from the dashboard until the update is complete
                // This is to make sure users can't see out of date report groups. We could optimistically update the group, but if the group changes
                // permissions, the use could still see it until a refetch is done
                queryClient.setQueryData([getDashboardKey, licenseId], (old: HomepageCards) => {
                    const newReportGroups = old?.reportGroups?.filter(group => group.id !== newGroup.id )
                    //return old
                    return {
                        reportGroups: newReportGroups,
                        reports: old?.reports || [],
                    }
                })

                return {previousReportGroups}
            },
            onError(_err, _newGroup, context: CreateReportGroupContext) {
                queryClient.setQueryData(
                    [getReportGroupsKey, licenseId],
                    context.previousReportGroups
                )
            },
            onSettled() {
                queryClient.invalidateQueries([getReportGroupsKey, licenseId])
            }
        }
    )
}