import { useParams } from 'react-router-dom'
import {
  useGetDashboard,
  useGetLicense,
  useGetReportGroupMembers,
} from '../../../hooks'
import { useEffect, useState } from 'react'
import { getLicenseState } from '../../../models'
import { FullPageLoader, Layout } from '../../../components'
import { Stack, Typography } from '@mui/material'
import {
  ReportTiles,
  ReportTilesSkeleton,
} from '../../reports/report-dashboard/report-tiles'
import { ErrorPage } from '../../reports/report-view/error-page'

export function ReportGroupView() {
  const routeParams = useParams<any>()
  const reportGroupId = routeParams.id
  const { data: license, isLoading: licenseLoading } = useGetLicense()
  const { data: homepageCards, isLoading: homepageLoading } = useGetDashboard()
  const { data: members, isLoading: membersLoading } =
    useGetReportGroupMembers(reportGroupId)

  const [skeletonNumber, setSkeletonNumber] = useState(6)

  const selectedReportGroup =
    homepageCards?.reportGroups?.find(group => group.id === reportGroupId) ||
    null

  // Check License validity
  const validLicense = getLicenseState(license).valid

  useEffect(() => {
    //Check if we have the number of reports in local storage
    const reportsInLocalStorage = localStorage.getItem(
      'reportGroupMembers' + reportGroupId
    )
    if (reportsInLocalStorage) {
      setSkeletonNumber(parseInt(reportsInLocalStorage))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (membersLoading) return
    localStorage.setItem(
      'reportGroupMembers' + reportGroupId,
      JSON.stringify(members.length)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membersLoading])

  if (licenseLoading) {
    return <FullPageLoader />
  }

  if (
    !selectedReportGroup &&
    !membersLoading &&
    !homepageLoading &&
    !licenseLoading
  ) {
    return (
      <ErrorPage>
        Error: You do not have access to this report group or the report group
        does not exist
      </ErrorPage>
    )
  }

  return (
    <Layout>
      <Stack
        sx={{
          m: 'auto',
          mb: 8,
          maxWidth: '1280px',
          px: { xs: 3.5, sm: 4.5 },
        }}
        gap={4}
        alignItems='center'
      >
        {!validLicense && (
          <>
            <Typography sx={{ mt: 10 }} variant='h6'>
              {`Your License expired on: ${license.subscriptionEndDate}. Please contact your license owner to reactivate the license.`}
            </Typography>
          </>
        )}
        {validLicense && (
          <>
            {members?.length === 0 && !membersLoading && (
              <Typography sx={{ mt: 10 }} variant='h6'>
                No reports to display
              </Typography>
            )}
            {members?.length > 0 &&
              !membersLoading &&
              !homepageLoading &&
              selectedReportGroup && (
                <ReportTiles
                  title={selectedReportGroup.name}
                  subtitle={`${selectedReportGroup.description} - Permission: ${selectedReportGroup.permissionTitle}`}
                  isLoading={membersLoading}
                  cards={members}
                  folders={null}
                />
              )}
            {(membersLoading || homepageLoading) && (
              <ReportTilesSkeleton numReports={skeletonNumber} title={''} />
            )}
          </>
        )}
      </Stack>
    </Layout>
  )
}
