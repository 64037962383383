import { Stack, Typography } from '@mui/material'
// import Grid from '@mui/material/Grid2'

import { ReportTiles, ReportTilesSkeleton } from './report-tiles'
import {
  useGetLicense,
  useCurrentUserInfo,
  useGetReports,
  useGetFolders,
} from '../../../hooks'
import { Redirect } from 'react-router-dom'
import { routes } from '../../../routes'
import { Layout } from '../../../components/layout'
import { FullPageLoader } from '../../../components/common'
import { getLicenseState } from '../../../models'
import { useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { bookmarkAtom } from '../../../state'
import { Folders, FolderSkeletons } from './folders'
import { isOnlyOneUniqueItem } from '../../../utils/uniqueItems'

export function ReportDashboard() {
  const { data: license, isLoading: licenseLoading } = useGetLicense()
  const { data: reports, isLoading: reportsLoading } = useGetReports()
  const {
    data: {
      user: { email: currentUserEmail },
    },
  } = useCurrentUserInfo()
  const { data: folders, isLoading: foldersLoading } = useGetFolders()

  const [myReportSkeletonNumber, setMyReportsSkeletonNumber] = useState(6)
  const [sharedReportSkeletonNumber, setSharedReportsSkeletonNumber] =
    useState(6)
  const [foldersSkeletonNumber, setFoldersSkeletonNumber] = useState(2)

  const myReports = reports?.ownedReports || []
  const sharedReports = reports?.sharedReports || []
  const defaultReports = reports?.defaultReports || []
  const allReports = [...myReports, ...sharedReports, ...defaultReports]

  const setBookmarkState = useSetRecoilState(bookmarkAtom)

  const defaultNotOwnedReports = defaultReports?.filter(
    report =>
      myReports.find(myReport => myReport.id === report.id) === undefined
  )
  const sharedWithMeReports = [
    ...new Map(
      [...sharedReports, ...defaultNotOwnedReports].map(item => [
        item['name'],
        item,
      ])
    ).values(),
  ]

  // Check License validity
  const validLicense = getLicenseState(license).valid

  useEffect(() => {
    //Check if we have the number of reports in local storage
    const myReportsInLocalStorage = localStorage.getItem('dashboardReports')
    if (myReportsInLocalStorage) {
      setMyReportsSkeletonNumber(parseInt(myReportsInLocalStorage))
    }

    const sharedReportsInLocalStorage = localStorage.getItem('sharedReports')
    if (sharedReportsInLocalStorage) {
      setSharedReportsSkeletonNumber(parseInt(sharedReportsInLocalStorage))
    }

    setBookmarkState({
      panelOpen: false,
      resetReportFilters: false,
      selectedBookmark: null,
      selectedBookmarkFilters: null,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    //Check if we have the number of reports in local storage
    const foldersInLocalStorage = localStorage.getItem('folders')
    if (foldersInLocalStorage) {
      setFoldersSkeletonNumber(parseInt(foldersInLocalStorage))
    }
  }, [foldersLoading])

  useEffect(() => {
    if (reportsLoading || !allReports) return
    localStorage.setItem('dashboardReports', JSON.stringify(myReports.length))

    localStorage.setItem(
      'sharedReports',
      JSON.stringify(sharedWithMeReports.length)
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsLoading])

  useEffect(() => {
    if (foldersLoading || !folders) return
    localStorage.setItem('folders', JSON.stringify(folders.length))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foldersLoading])

  if (licenseLoading) {
    return <FullPageLoader />
  }

  // We are done loading
  if (isOnlyOneUniqueItem(allReports.map(r => r.id)) && validLicense) {
    return (
      <Redirect
        to={routes.reports.report({
          id: allReports[0].id,
        })}
      />
    )
  }

  return (
    <Layout>
      <Stack
        sx={{
          m: 'auto',
          mb: 8,
          width: '100%',
          px: { xs: 3.5, sm: 4.5 },
        }}
        alignItems='center'
      >
        {!validLicense && (
          <>
            <Typography sx={{ mt: 10 }} variant='h6'>
              {`Your License expired on: ${license.subscriptionEndDate}. Please contact your license owner to reactivate the license.`}
            </Typography>
          </>
        )}

        <>
          {validLicense && (
            <>
              {myReports.length === 0 &&
                sharedWithMeReports.length === 0 &&
                !reportsLoading && (
                  <Typography sx={{ mt: 10 }} variant='h6'>
                    No reports to display
                  </Typography>
                )}

              {reportsLoading ? (
                <>
                  {myReportSkeletonNumber > 0 && (
                    <ReportTilesSkeleton
                      numReports={myReportSkeletonNumber}
                      title='My Reports'
                    />
                  )}
                  {sharedReportSkeletonNumber > 0 && (
                    <ReportTilesSkeleton
                      numReports={sharedReportSkeletonNumber}
                      title='Shared With Me'
                    />
                  )}
                </>
              ) : (
                <>
                  {myReports.length > 0 && (
                    <ReportTiles
                      title='My Reports'
                      subtitle={currentUserEmail}
                      isLoading={reportsLoading}
                      cards={myReports}
                      folders={folders}
                    />
                  )}
                  {sharedReports.length > 0 && (
                    <ReportTiles
                      title='Shared With Me'
                      subtitle={''}
                      isLoading={reportsLoading}
                      cards={sharedReports}
                      folders={folders}
                      
                    />
                  )}
                </>
              )}
            </>
          )}
        </>

        {foldersLoading ? (
          <FolderSkeletons numFolders={foldersSkeletonNumber} />
        ) : (
          <Folders folders={folders} />
        )}
      </Stack>
    </Layout>
  )
}
