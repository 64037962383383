import {
  useAddReportGroupMembers,
  useGetUnassignedLicenseReports,
  useGetReportGroupMembers,
} from '../../../../hooks'
import { ReportGroup, ApiReport } from '../../../../models'
import { LinkItemsMenu } from '../../shared'

type LinkReportsMenuProps = {
  reportGroup: ReportGroup
}

export const LinkReportsMenu = (props: LinkReportsMenuProps) => {
  const { reportGroup } = props
  const { data: reports } = useGetUnassignedLicenseReports()
  const { data: linkedReports } = useGetReportGroupMembers(reportGroup.id)
  const { mutate: addReportGroupMembers } = useAddReportGroupMembers()

  const linkedReportsNotLoaded = linkedReports
    ?.map((r: ApiReport) => {
      return !!r?.id
    })
    .some((r: boolean) => r === false)

  const linkedReportIds =
    (linkedReports &&
      !linkedReportsNotLoaded &&
      linkedReports.map((r: ApiReport) => r.id)) ||
    []

  const reportsAvailableToAdd =
    (Array.isArray(reports) &&
      linkedReportIds &&
      reports.filter((r: ApiReport) => !linkedReportIds.includes(r.id))) ||
    []

  const linkReports = (reportIds: string[]) => {
    addReportGroupMembers({
      reportGroupId: reportGroup.id,
      reportIds: reportIds,
    })
  }

  return (
    <LinkItemsMenu
      onConfirm={linkReports}
      addButtonLabel='Add Reports'
      placeHolderText='Search Reports'
      availableItems={
        (reportsAvailableToAdd &&
          reportsAvailableToAdd.map((r: ApiReport) => {
            return {
              id: r.id,
              name: r.name || r.name,
              owner: r.owner,
              pbiReportId: r.pbiReportId,
              pbiWorkspaceId: r.pbiWorkspaceId,
            }
          })) ||
        []
      }
    />
  )
}
