import { useRecoilValue } from 'recoil'
import { ApiReport } from '../../models'
import { licenseAtom } from '../../state'
import { reportGroupApi } from '../../api-interface'
import { useMutation, useQueryClient } from 'react-query'
import {
  getUnassignedLicenseReportsKey,
  getReportGroupMembersKey,
} from '../queries'

type AddReportGroupMemberPayload = {
  reportGroupId: string
  reportIds: string[]
}

export function useAddReportGroupMembers() {
  const licenseId = useRecoilValue(licenseAtom)
  const queryClient = useQueryClient()

  return useMutation(
    (payload: AddReportGroupMemberPayload) =>
      reportGroupApi.addReportGroupMember({
        reportGroupId: payload.reportGroupId,
        reportIds: payload.reportIds,
      }),
    {
      async onMutate(
        payload: AddReportGroupMemberPayload
      ): Promise<ApiReport[]> {
        await queryClient.cancelQueries([
          getReportGroupMembersKey,
          payload.reportGroupId,
        ])
        await queryClient.cancelQueries([
          getUnassignedLicenseReportsKey,
          licenseId,
        ])

        const allReports: ApiReport[] =
          queryClient.getQueryData([
            getUnassignedLicenseReportsKey,
            licenseId,
          ]) || []

        const addedReports: ApiReport[] = allReports.filter((r: ApiReport) => {
          return payload.reportIds.includes(r.id)
        })

        const previousReports: ApiReport[] = queryClient.getQueryData([
          getReportGroupMembersKey,
          payload.reportGroupId,
        ])

        const updatedReports: ApiReport[] = [
          ...(previousReports || []),
          ...addedReports,
        ]

        queryClient.setQueryData(
          [getReportGroupMembersKey, payload.reportGroupId],
          updatedReports
        )

        queryClient.setQueryData(
          [getUnassignedLicenseReportsKey, licenseId],
          (old: ApiReport[]) => {
            const updatedReports = old.filter(
              (oldReport: ApiReport) =>
                !payload.reportIds.includes(oldReport.id)
            )
            return updatedReports
          }
        )

        return previousReports || []
      },
    }
  )
}
