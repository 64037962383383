import { useRecoilValue } from "recoil"
import { licenseAtom } from "../../state"
import { useQuery } from "react-query"
import { reportGroupApi } from "../../api-interface"

export const getReportGroupsKey = 'get-report-groups'

export function useGetReportGroups() {
    const licenseId = useRecoilValue(licenseAtom)
    return useQuery(
        [getReportGroupsKey, licenseId],
        () => reportGroupApi.getReportGroups(licenseId),
        {
            enabled: !!licenseId,
        }
    )
}